import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveIcon from "@material-ui/icons/Remove";
import CircularProgress from "@material-ui/core/CircularProgress";

import { history } from "../../../../routes/index";
import useStyles from "./styles";
import CustomTextField from "../../../../components/CustomTextField";
import CustomCheckboxField from "../../../../components/CustomCheckboxField";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import userFormValidate from "../../../../validators/userFormValidate";

let UserForm = ({
  handleSubmit,
  textFields,
  roles,
  parentSubmitHandle,
  valid,
}) => {
  const classes = useStyles();

  const previousRoute = useSelector((state) => state.route.previousRoute);
  const userLoading = useSelector((state) => state.users.loading);

  const renderTextFields = useCallback(
    () =>
      textFields.map((field, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Field
            component={CustomTextField}
            name={field.name}
            label={field.label}
            isPassword={field.isPassword}
          />
        </Grid>
      )),
    [textFields]
  );

  const renderCheckboxFields = useCallback(
    ({ fields }) =>
      fields.map((field, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Field
            name={field}
            component={CustomCheckboxField}
            label={roles[index].label}
          />
        </Grid>
      )),
    [roles]
  );

  const renderPhoneFields = useCallback(({ fields }) => {
    return (
      <>
        {fields.getAll() &&
          fields.map((field, index) => {
            return (
              <Grid
                item
                sm={12}
                key={index}
                style={{ display: "flex", gap: 16 }}
              >
                <Field
                  name={`${field}.number`}
                  label={`Telefone ${index + 1}`}
                  adornment={
                    <Tooltip title="Remover telefone" aria-label="remove">
                      <RemoveIcon fontSize="small" />
                    </Tooltip>
                  }
                  parentAdornmentHandleClick={() => fields.remove(index)}
                  component={CustomTextField}
                />
                <Field
                  name={`${field}.smsSmn`}
                  component={CustomCheckboxField}
                  label="SMN"
                />
              </Grid>
            );
          })}

        {fields.length < 4 && (
          <Grid
            item
            xs={12}
            sm={6}
            container
            justify="center"
            alignItems="center"
          >
            <Button
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => fields.push("")}
            >
              {console.log()}
              Adicionar telefone
            </Button>
          </Grid>
        )}
      </>
    );
  }, []);

  return (
    <Grid
      container
      component="form"
      classes={{ root: classes.formContainer }}
      onSubmit={handleSubmit(parentSubmitHandle)}
    >
      <Grid item xs={12} md={6} classes={{ root: classes.formLeftSide }}>
        <Box className={classes.formSubtitleContainer}>
          <Typography classes={{ root: classes.formSubtitle }}>
            Entre com os dados do usuário
          </Typography>
        </Box>

        <Divider />

        <Box classes={{ root: classes.fieldsContainer }}>
          <Grid container spacing={3} style={{ marginBottom: 8 }}>
            {renderTextFields()}
            <FieldArray name="phones" component={renderPhoneFields} />
          </Grid>
          <Field
            name="usesClientIntegration"
            component={CustomCheckboxField}
            label="Habilitar integração"
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} classes={{ root: classes.formRightSide }}>
        <Box className={classes.formSubtitleContainer}>
          <Typography classes={{ root: classes.formSubtitle }}>
            Selecione os cargos a serem atribuídos ao usuário
          </Typography>
        </Box>

        <Divider />

        <Grid container spacing={3} classes={{ root: classes.fieldsContainer }}>
          {roles.length <= 0 && (
            <Typography
              variant="subtitle2"
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
            >
              Não há cargos disponíveis para o cliente selecionado
            </Typography>
          )}
          {roles.length > 0 && (
            <FieldArray name="roles" component={renderCheckboxFields} />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} container justify="center">
        <Grid item xs={6} classes={{ root: classes.buttonContainer }}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ marginRight: 20 }}
            classes={{ root: classes.button }}
            onClick={() => history.push(previousRoute)}
          >
            <Typography color="secondary" variant="button">
              Cancelar
            </Typography>
          </Button>
          <Button
            variant="contained"
            color={!userLoading ? "secondary" : "default"}
            type="submit"
            disabled={!valid}
            classes={{ root: classes.button }}
          >
            {userLoading && (
              <>
                <CircularProgress
                  color="secondary"
                  size={20}
                  style={{ marginRight: 7 }}
                />
                <Typography color="secondary" variant="button">
                  Salvando
                </Typography>
              </>
            )}
            {!userLoading && <Typography variant="button">Salvar</Typography>}
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar variant="error" />
    </Grid>
  );
};

export default reduxForm({
  form: "user",
  validate: userFormValidate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(UserForm);
